import React from "react"
//import Header from "../components/Header"
//import Footer from "../components/Footer"
import "./layout.css"
const Layout = ({ children }) => {
  return (
    < >
   
      <>
        <main>
        {children}
        
        </main>
      </>
       
  
    </>
  )
}
export default Layout